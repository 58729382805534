import React, { Component } from "react";
import Header from "./Header";
import HeroBlock from "./HeroBlock";
import Content from "./Content";

export default class Home extends Component {
  render() {
    return (
      <div className="site-content">
        <Header/>
        <HeroBlock/>
        <Content/>
      </div>
    );
  }
}
