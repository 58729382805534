import React from 'react';

class Header extends React.Component {
  render() {
    return (
      <header className="site-header header-style-one">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="navigation-area">
                <div className="site-branding">
                  <h2>Yayan Heryanto</h2>
                </div>
                <div className="site-navigation">
                  <nav className="navigation">
                    <div className="menu-wrapper">
                      <div className="menu-content">
                        <ul className="mainmenu">
                          <li>
                            <a className="nav-link active" href="#home">
                              Home
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#about">
                              About
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#skills">
                              Skills
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#journey">
                              Journey
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#portfolio">
                              Portfolio
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#blog">
                              Blog
                            </a>
                          </li>
                          <li>
                            <a className="nav-link" href="#contact">
                              Contact
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
                <div className="header-navigation-right">
                  <div className="hamburger-menus">
                    <span />
                    <span />
                    <span />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mobile-sidebar-menu sidebar-menu">
          <div className="overlaybg" />
        </div>
      </header>
    );
  }
}

export default Header;
