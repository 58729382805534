import React from 'react';

class Blog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      blogData: []
    };
  }

  componentDidMount() {
    fetch('/assets/json/blog.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ blogData: data });
        console.log(data);
      })
      .catch(error => console.error('Error fetching blog data:', error));
  }

  render() {
    const { blogData } = this.state;

    return (
      <div id="blog" className="latest-news-block style-one">
        <div
          className="waves-effect top"
          style={{
            background: 'url("./assets/images/shape/shape-top3.png")',
          }}
        />
        <div
          className="waves-effect bottom"
          style={{
            background: 'url("./assets/images/shape/shape-bottom1.png")',
          }}
        />
        <div className="container ml-b-45">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="section-title text-center">
                <h2 className="title-main">Blog</h2>
              </div>
            </div>
          </div>

          <div className="row">
            {blogData.map((blog, index) => (
              <div key={index} className="col-lg-4 col-md-6 card card-no-padding">
                <article className="post post-grid">
                  {/* <div className="post-thumb-area">
                    <figure className="post-thumb">
                      <img
                        src={blog.imageUrl}
                        alt="Blog Image"
                        className="img-cover"
                      />
                    </figure>
                  </div> */}
                  <div className="post-details post-padding">
                    <h2 className="entry-title">
                      <a href={blog.url}>
                        <span>{blog.title}</span>
                      </a>
                    </h2>
                    <span>
                      {blog.description}
                      <a href={blog.url}>
                        <span className='read-more-blog'>Read More</span>
                      </a>
                    </span>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Blog;
