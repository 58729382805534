import React from 'react';

class HeroBlock extends React.Component {
  render() {
    return (
      <>
        <div id="sticky-header" className="active" />
        <div id="home" className="hero-block bg-white-smoke hero-block-one">
          <div className="element-group">
            <div className="element one">
              <img src="./assets/images/element/rectangle2.png" alt="Element" />
            </div>
          </div>
          <div className="social-status-area">
            <div className="social-status">
              <a href="https://www.linkedin.com/in/yayanheryanto27/">
                <i className="icofont-linkedin" />
              </a>
              <a href="https://github.com/yayancfc">
                <i className="icofont-github" />
              </a>
            </div>
          </div>
          <div
            className="waves-effect bottom"
            style={{
              backgroundImage: 'url("./assets/images/shape/shape-bottom1.png")',
            }}
          ></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div id="hero-slider" className="swiper-container hero-slider-one">
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="hero-content-area">
                        <h2 className="hero-title">
                          <span>Hi, I'm</span> Yayan Heryanto
                        </h2>
                        <div className="hero-desc">
                          <h3>Mobile Developer</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-pagination" />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="hero-mockup-area">
                  <div className="hero-mockup-thumb one">
                    <img src="./assets/images/others/hero2.png" alt="Thumb" />
                    <div className="element-group">
                      <div className="element one">
                        <img src="./assets/images/element/circle2.png" alt="Element" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default HeroBlock;
