import React, { useState, useEffect } from 'react';

const Journey = () => {
  const [journeyData, setJourneyData] = useState(null);

  useEffect(() => {
    fetch('/assets/json/journey.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setJourneyData(data))
      .catch(error => console.error('Error fetching journey data:', error));
  }, []);

  if (!journeyData) {
    return <div>Loading...</div>;
  }

  const renderSection = (sectionKey) => {
    const section = journeyData[sectionKey];
    return (
      <div className="col-lg-6 col-md-6">
        <div className="section-title text-center">
          <img src={section.icon} width={'60px'} alt={`${section.title} Icon`} className="icon" />
          <h2 className="section-title">{section.title}</h2>
        </div>
        <div className="content">
          {section.items.map((item, index) => (
            <div className="item" key={index}>
              <div className="circle">
                {<div className="vertical-line"></div>}
              </div>
              <div className="details">
                {sectionKey === 'education' ? (
                  <>
                    <h3>{item.degree}</h3>
                    <p>{item.institution}</p>
                  </>
                ) : (
                  <h3>{item.company}</h3>
                )}
                <p>{item.role}</p>
                <div className="date">{item.dateRange}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div id="journey" className="latest-news-block style-one">
      <div className="waves-effect top" style={{ background: 'url("./assets/images/shape/shape-top2.png")' }} />
      <div className="waves-effect bottom" style={{ background: 'url("./assets/images/shape/shape-bottom3.png")' }} />
      <div className="container ml-b-45">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center">
              <h2 className="title-main">Journey</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {renderSection('education')}
          {renderSection('experience')}
        </div>
      </div>
    </div>
  );
};

export default Journey;
