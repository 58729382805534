import React from 'react';
import Portfolio from './Portfolio';
import About from "./About";
import Skills from "./Skills";
import Journey from "./Journey";
import Blog from "./Blog";
import Space from "./Space";
import Contact from "./Contact";

class Content extends React.Component {
  render() {
    return (
        <div
        id="about"
        className="services-block style-one"
        style={{ paddingBottom: "100px" }}
      >
        <About/>
        <Skills/>
        <Journey/>
        <Portfolio/>
        <Blog/>
        <Space/>
        <Contact/>
      </div>
    );
  }
}

export default Content;
