import React from 'react';

class Skills extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      skillsData: []
    };
  }

  componentDidMount() {
    fetch('/assets/json/skills.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.setState({ skillsData: data });
        console.log(data);
      })
      .catch(error => console.error('Error fetching skills data:', error));
  }

  render() {
    const { skillsData } = this.state;

    return (
      <div className="latest-news-block style-one bg-white-smoke" id="skills">
        <div
          className="waves-effect top"
          style={{
            background: 'url("./assets/images/shape/shape-top2.png")',
          }}
        />
        <div
          className="waves-effect bottom"
          style={{
            background: 'url("./assets/images/shape/shape-bottom3.png")',
          }}
        />
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center">
              <h2 className="title-main">Skills</h2>
            </div>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <ul className="list-inline mx-auto skill-icon">
            {skillsData.slice(0, 6).map((skill, index) => (
              <li className="list-inline-item mx-3" key={index}>
                <span>
                  <div className="text-center skills-tile">
                    <i className={`${skill.icon} colored`} style={{ fontSize: '220%' }}>
                      <p className="text-center" style={{ fontSize: '30%', marginTop: '4px' }}>
                        {skill.name}
                      </p>
                    </i>
                  </div>
                </span>
              </li>
            ))}
          </ul>

          <ul className="list-inline mx-auto skill-icon">
            {skillsData.slice(6).map((skill, index) => (
              <li className="list-inline-item mx-3" key={index}>
                <span>
                  <div className="text-center skills-tile">
                    <i className={`${skill.icon} colored`} style={{ fontSize: '220%' }}>
                      <p className="text-center" style={{ fontSize: '30%', marginTop: '4px' }}>
                        {skill.name}
                      </p>
                    </i>
                  </div>
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Skills;
