import React from 'react';

class Contact extends React.Component {
    render() {
      return (
        <div id="contact" className="contact-form-block style-two">
            <div className="element-group">
              <div className="element two">
                <img src="./assets/images/element/circle3.png" alt="Element" />
              </div>
            </div>
            <div className="container ml-b-40">
              <div className="row">
                <div className="col-lg-5 col-md-6">
                  <div className="section-title">
                    <h2 className="title-main">
                      Don’t Hesitate to Contact me.
                    </h2>
                  </div>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="single-contact-info">
                        <h3 className="title">Contacts</h3>
                        <div className="card-info">
                          <ul className="info-list">
                            <li>
                              <i className="icofont-send-mail" />{" "}
                              <span>hallo@yayan.space</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="social-status">
                          <a href="https://www.linkedin.com/in/yayanheryanto27/">
                            <i className="icofont-linkedin" />
                          </a>
                          <a href="https://github.com/yayancfc">
                            <i className="icofont-github" />
                          </a>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      );
    }
  }
  
  export default Contact;
  