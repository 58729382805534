import React from 'react';

class About extends React.Component {
  render() {
    return (
      <div id="about" className="about-section">
        <div className="element-group">
          <div className="element one">
            <img src="./assets/images/element/circle-helf1.png" alt="Element" />
          </div>
        </div>

        <div className="container">
          <div className="row align-items-center md-wrap-reverse">
            <div className="col-lg-6">
              <div className="mock-up-thumb">
                <img src="./assets/images/about/about.png" alt="About Mock" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="text-feature-block md-mrb-55">
                <h2 className="title">About</h2>
                <div>
                  <div>
                    <div className="card">
                      <div className="card-header">
                        <span
                          className="iconify"
                          data-icon="emojione:red-circle"
                          data-inline="false"
                        ></span>
                        &nbsp;
                        <span
                          className="iconify"
                          data-icon="twemoji:yellow-circle"
                          data-inline="false"
                        ></span>
                        &nbsp;
                        <span
                          className="iconify"
                          data-icon="twemoji:green-circle"
                          data-inline="false"
                        ></span>
                      </div>
                      <div
                        className="card-body font-trebuchet text-justify ml-3 mr-3"
                        style={{
                          height: 'auto',
                          fontSize: '100%',
                          lineHeight: '150%',
                        }}
                      >
                        <p>Hi, I'm Yayan Heryanto.</p>
                        Passionate about programming, particularly in Mobile Development. Possess strong logical thinking skills and the ability to continuously self-develop. Adaptable to new environments and eager to learn new technologies.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
