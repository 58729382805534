import React from 'react';

class Space extends React.Component {
    render() {
      return (
        <div
            id="portfolio"
            className="latest-news-block style-one bg-white-smoke"
          >
            <div
              className="waves-effect top"
              style={{
                background: 'url("./assets/images/shape/shape-top3.png")',
              }}
            />
            <div
              className="waves-effect bottom"
              style={{
                background: 'url("./assets/images/shape/shape-bottom1.png")',
              }}
            />
           
          </div>
      );
    }
  }
  
  export default Space;
  