import React, { useState, useEffect } from 'react';

const Portfolio = () => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    fetch('/assets/json/portfolio.json')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => setPortfolioData(data))
      .catch(error => console.error('Error fetching portfolio data:', error));
  }, []);

  useEffect(() => {
    if (isPopupVisible) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isPopupVisible]);

  const handlePopupOpen = (item) => {
    setSelectedItem(item);
    setCurrentImageIndex(0); // Reset to the first image
    setPopupVisible(true);
  };

  const handlePopupClose = () => {
    setPopupVisible(false);
    setSelectedItem(null);
  };

  const handlePrevImage = () => {
    if (selectedItem && currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const handleNextImage = () => {
    if (selectedItem && currentImageIndex < selectedItem.images.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  if (!portfolioData.length) {
    return <div>Loading...</div>;
  }

  return (
    <div id="portfolio" className="latest-news-block style-one bg-white-smoke">
      <div
        className="waves-effect top"
        style={{
          background: 'url("./assets/images/shape/shape-top2.png")',
        }}
      />
      <div
        className="waves-effect bottom"
        style={{
          background: 'url("./assets/images/shape/shape-bottom3.png")',
        }}
      />
      <div className="container ml-b-45">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title text-center">
              <h2 className="title-main">Portfolio</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {portfolioData.map((item, index) => (
            <div key={index} className="col-lg-4 col-md-6">
              <article className="post post-grid">
                <div className="post-thumb-area" onClick={() => handlePopupOpen(item)}>
                  <figure className="post-thumb">
                    <img
                      src={item.images[0]}
                      alt="Portfolio Image"
                      className="img-cover"
                    />
                  </figure>
                </div>
                <div className="post-details">
                  <h2 className="entry-title">
                  {item.url ? (
                      <a href={item.url}>{item.title}</a>
                    ) : (
                      <span>{item.title}</span>
                    )}
                  </h2>
                </div>
              </article>

              {isPopupVisible && selectedItem && (
                <div className="popup">
                  <div className="popup-content">
                    <div><span className="close" onClick={handlePopupClose}>&times;</span></div>
                    <div className="popup-image-container">
                      <img
                        src={selectedItem.images[currentImageIndex]}
                        alt="Popup Image"
                        className="popup-image"
                      />
                      <button className="popup-arrow left" onClick={handlePrevImage}>&lt;</button>
                      <button className="popup-arrow right" onClick={handleNextImage}>&gt;</button>
                    </div>

                  <br/>
                  <br/>
                    <div className="popup-description">
                    {selectedItem.url ? (
                      <a href={selectedItem.url}><h3>{selectedItem.title}</h3></a>
                    ) : (
                      <h3>{selectedItem.title}</h3>
                    )}
                      <p>{selectedItem.popupDescription}</p>
                      <div className="tech-icons">

                      <ul className="list-inline mx-auto skill-icon">
                        {selectedItem.techIcons.map((tech, index) => (

                          <li className="list-inline-item mx-3" key={index}>
                               <i className={`${tech} colored`} style={{ fontSize: '180%' }}></i>
                          </li>
                          
                        ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
